import React from "react"
import SEO from "../../components/CommonComponents/SEO"
import Layout from "../../components/CommonComponents/Layout"
import Hero from "../../components/SingleService/UiDesign/Hero";
import Services from "../../components/SingleService/UiDesign/Services";
import Experience from "../../components/SingleService/UiDesign/Experience";
import Keys from "../../components/SingleService/UiDesign/Keys";
import Technologies from "../../components/SingleService/UiDesign/Technologies";
import Importance from "../../components/SingleService/UiDesign/Importance/Importance";
import Choose from "../../components/SingleService/UiDesign/Choose"
import dataForUiDesign from "../../information/Services/dataForUiDesign"
import ContactUsExpertise from "../../components/CommonComponents/ContactUsExpertise"
import Cases from "../../components/CommonComponents/Cases";
import { mainUrl } from "../../js/config"
import dataForProjects from "../../information/CloudSolutions/dataForProjects.json";

const settings = {
  dots: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  arrows: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        className: "center",
      },
    },
  ],
}

export default function UiUxDesign() {
  const sendUrl = typeof window !== "undefined" ? window?.location?.href : "";
  const { ogImage, hero, services, importance, choose, keys, technologies } = dataForUiDesign;
  return (
    <div>
      <SEO
        title="UI/UX Design Services | OS-System"
        description="Looking for professional UI and UX design services? Our expert team creates user-centered digital products that drive engagement and boost conversions. Get a free design consultation. "
        canonical={`${mainUrl}/services/uiuxdesign/`}
        ogImage={ogImage}
      />
      <Layout showFormPoint={100}>
        <Hero data={hero} />
        <Services data={services} />
        <Experience additionalWord="& designers" />
        <Importance data={importance} />
        <Choose data={choose} />
        <Keys data={keys} />
        <Technologies data={technologies} isCompact={true} />
        <Cases
          projects={dataForProjects}
          settings={settings}
          headerText="Work Projects"
          customClass="cloud"
        />
        <ContactUsExpertise form={0} url={sendUrl} />
      </Layout>
    </div>
  )
}
