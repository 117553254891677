import React from "react";
import './Technologies.scss';

const TechRow = ({ rowName, techArray, isCompact }) => {
  return (
    <div className={`tech-ui-container ${isCompact ? 'compact' : ''}`}>
      {!isCompact && (
        <div className="row-name">
          {rowName}:
        </div>
      )}
      <div className={`tech-row ${isCompact ? 'compact' : ''}`}>
        {techArray.map((el, index) => (
          <div key={index} className={`img-container ${isCompact ? 'compact' : ''}`} > 
            <img src={el} className={`${isCompact ? 'compact' : ''}`} alt={`Technology ${index}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

const Technologies = ({ data, isCompact = false }) => {
  const { title, techData } = data;
  return (
    <div className={`tech-ui-wrapper ${isCompact ? 'compact' : ''}`}>
      <div className={`tech-ui-content ${isCompact ? 'compact' : ''}`}>
        <h2 className="title">
          {title}
        </h2>
        {Object.entries(techData).map(([key, value]) => (
          <TechRow key={key} rowName={key} techArray={value} isCompact={isCompact} />
        ))}
      </div>
    </div>
  );
};

export default Technologies;