import React from "react";

import "./Experience.scss"

const Case = ({ data }) => {
  const { number, title, subtitle_1, subtitle_2 } = data;
  return (
    <div className={`experience-ui-case`}>
      <div className="case-number">{number}</div>
      <p className="case-title">{title}</p>
      <div className="case-subtitle">{subtitle_1}</div>
      <p className="case-subtitle">{subtitle_2}</p>
    </div>
  )
}

export default function Experience({ additionalWord = "", additionalStyle = "" }) {
  const data = [
    {
      number: "18",
      title: "Years",
      subtitle_1: "in web & mobile app development"
    },
    {
      number: "40+",
      title: `Developers ${additionalWord}`,
      subtitle_1: "experienced",
      subtitle_2: "in software engineering",
      additionalStyle,
    },
    {
      number: "120+",
      title: "Clients",
      subtitle_1: "satisfied with the",
      subtitle_2: "project results"
    },
    {
      number: "300+",
      title: "Projects",
      subtitle_1: "succesfully",
      subtitle_2: "completed"
    },
  ];

  return (
    <div className="experience-ui-wrapper">
      <div className={`experience-ui-content`}>
        {data.map((expirieceData) => <Case data={expirieceData} />)}
      </div>
    </div>
  );
};
