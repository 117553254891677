import React from "react";
import './Services.scss';

const CaseComponent = ({ data }) => {
  const { title, description, img } = data;
  return (
    <div className="ui-service-case">
      <div className="title-container">
        <div className="img-container">
          <img src={img} alt="web development icon" />
        </div>
        <h3 className="title">{title}</h3>
      </div>
      <div className="description">{description}</div>
    </div>
  );
};
export default function Services({ data }) {
  const { title, description, cases } = data;
  return (
    <section className="ui-service-wrapper">
      <div className="ui-service-text">
        <h2 className="ui-service-title">{title}</h2>
        <div className="ui-service-description">{description}</div>
      </div>
      <div className="ui-service-cases">
        {cases.map((caseData) => <CaseComponent data={caseData} />)}
      </div>
    </section>
  );
}