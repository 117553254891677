import OgImage from "../../images/Services/UiDesign/og_image.png";
import Hero from "../../images/Services/UiDesign/hero.svg";
// Importance
import Trust from "../../images/Services/UiDesign/importance/trust.svg";
import Impressions from "../../images/Services/UiDesign/importance/impressions.svg";
import Loading from "../../images/Services/UiDesign/importance/loading.svg";
import Mobile from "../../images/Services/UiDesign/importance/mobile.svg";
import Smooth from "../../images/Services/UiDesign/importance/smooth.svg";

// Keys
import Discover from "../../images/Services/UiDesign/keys/discover.svg";
import Implementation from "../../images/Services/UiDesign/keys/implementation.svg";
import Information from "../../images/Services/UiDesign/keys/Information.svg";
import Prototyping from "../../images/Services/UiDesign/keys/prototyping.svg";
import Visual from "../../images/Services/UiDesign/keys/visual.svg";
import Wire from "../../images/Services/UiDesign/keys/wire.svg";

// Services
import Brandbook from "../../images/Services/UiDesign/services/brandbook.svg";
import Design from "../../images/Services/UiDesign/services/design.svg";
import MVP from "../../images/Services/UiDesign/services/mvp.svg";
import Product from "../../images/Services/UiDesign/services/product.svg";
import UI from "../../images/Services/UiDesign/services/ui.svg";
import User from "../../images/Services/UiDesign/services/user.svg";

// Tech
import Illustrator from "../../images/Services/UiDesign/tech/illustrator.svg";
import Material from "../../images/Services/UiDesign/tech/material.svg";
import Photoshop from "../../images/Services/UiDesign/tech/photoshop.svg";
import Sketch from "../../images/Services/UiDesign/tech/sketch.svg";
import Figma from "../../images/Services/Qa/tech/figma.svg";
import Redmine from "../../images/Technology/StackSection/redmine.svg";

const dataForUiDesign = {
  service: "ui",
  ogImage: OgImage,
  hero: {
    title: "UI/UX Design Services",
    subtitle: "Turn your ideas into engaging digital experiences with UI/UX services from OS-System.",
    description: "Get a free consultation with our UX experts and discover how smart design can boost your conversions.",
    img: Hero
  },
  services: {
    title: "Our UI and UX Design Services",
    description: "We craft digital experiences that blend beauty with function. UI/UX design services deliver interfaces that catch the eye and make users stick around. Each project starts with deep research and ends with pixel-perfect execution.",
    cases: [
      {
        title: "UI/UX Design",
        description: "We turn complex user needs into simple and intuitive interfaces. Our team analyses user behavior, creates wireframes, and crafts polished designs. Your users will be smoothly guided through your digital product.",
        img: UI,
      },
      {
        title: "Product Redesign",
        description: "We redesign already existing products. We fix usability issues, modernize interfaces, and sweeten user flows. Our redesigns increase engagement without hurting your core functionality.",
        img: Product,
      },
      {
        title: "MVP Design",
        description: "We redesign already existing products. We fix usability issues, modernize interfaces, and sweeten user flows. Our redesigns increase engagement without hurting your core functionality.",
        img: MVP,
      },
      {
        title: "Brandbook Design",
        description: "We will build comprehensive brand guidelines on colors, typography, components, and their usage rules. We will help you make the design consistent in every touchpoint and help teams move quickly.",
        img: Brandbook,
      },
      {
        title: "User Research",
        description: "We uncover user needs through interviews, testing, and behavior analysis. This research then shapes design decisions and helps to create products that people will actually use.",
        img: User,
      },
      {
        title: "Design System Creation",
        description: "We build scalable design systems out of reusable components, patterns, and rules that speed up development. Our UI/UX design services company keeps in mind your product to make consistent implementation easier and updates later on.",
        img: Design,
      },
    ]
  },
  importance: {
    title: "Importance of UI/UX design for conversions",
    description: "Quality design has a direct impact on your bottom line. When users enjoy interacting with your product, they convert more, stay longer, and recommend it to others. Here's how good UX/UI design services drive business growth.",
    cases: [
      {
        title: "First Impressions Count",
        description: "Users form opinions about your website in seconds. The clean, professional design builds instant trust and credibility. It reduces bounce rates and increases the chances visitors will go further.",
        img: Impressions,
      },
      {
        title: "Smooth User Journeys",
        description: "Clear navigation and thoughtful user flows remove friction from key actions like purchases or sign-ups. When users can find what they need easily, conversion rates naturally go up.",
        img: Smooth,
      },
      {
        title: "Mobile Performance",
        description: "Finding and fixing bugs during development is significantly cheaper than addressing them post-release. Early QA testing prevents costly emergency fixes, reduces development rework, and helps maintain efficient release schedules.",
        img: Mobile,
      },
      {
        title: "Loading Speed & Performance",
        description: "Fast-loading pages and smooth interactions keep users in. Even tiny delays can cause potential visitors to be frustrated. They can easily tank conversion rates, especially on mobile devices.",
        img: Loading,
      },
      {
        title: "Trust & Credibility",
        description: "Professional design signals reliability. When users trust your interface, they are more comfortable with sharing information and completing purchases. This boosts conversion rates.",
        img: Trust,
      },
    ]
  },
  choose: {
    title: 'Why you should choose us',
    cases: [
      {
        number: "01",
        title: "Research-Driven Approach",
        description: "We drive every design decision with great user research and data analysis. Our team will delve deep into user behavior patterns and market trends. This is crucial to create an interface that really speaks to your target audience.",
      },
      {
        number: "02",
        title: "End-to-End Solutions",
        description: "From the initial concept to the final implementation, we handle the complete design cycle. This unified approach makes sure that every stage is coherent and of quality. UI/UX design services from OS-System will save you precious time and resources.",
      },
      {
        number: "03",
        title: "Fast Delivery",
        description: "Our streamlined process, coupled with our experienced team, assures you of quicker turnarounds, not at the expense of quality. We break projects into sprints and deliver working results you can test and validate early.",
      },
      {
        number: "04",
        title: "Industry Experience",
        description: "Having worked across various sectors, we understand the different market needs and user expectations. This knowledge helps us create designs that work specifically for your industry and users.",
      },
      {
        number: "05",
        title: "Proven Results",
        description: "Our UI and UX design services ensure increases in metrics such as conversion rates, user engagement, and customer satisfaction. We track success by monitoring concrete data and user feedback.",
      },
    ]
  },
  keys: {
    title: "Stages of UI and UX design",
    cases: [
      {
        title: "Discover & Research",
        description: "Our UX/UI design service starts with analyzing your business goals, studying the competition, and researching your users' needs. OS-System designers conduct interviews and look for information regarding patterns and motivations that drive choices that benefit your users",
        number: "01",
        img: Discover,
      },
      {
        title: "Information Architecture",
        description: "We design based on user flows and site maps as structural models for content organization. This way, we show navigation methods for intuitive and accessible findability.",
        number: "02",
        img: Information,
      },
      {
        title: "Wire-framing",
        description: "We design low-fidelity mockups depicting layout and functionality. In other words, these wireframes allow us to test different approaches and get fast feedback before detailed design work is started.",
        number: "03",
        img: Wire,
      },
      {
        title: "Visual Designgn",
        description: "We design the look and feel of your interface: color, typography, and components. Every visual element supports both your brand and easy user interaction.",
        number: "04",
        img: Visual,
      },
      {
        title: "Prototyping & Testing",
        description: "We create interactive prototypes and test them with users. This uncovers usability issues early on, allowing us to refine the design based on real user feedback.",
        number: "05",
        img: Prototyping,
      },
      {
        title: "Implementation Support",
        description: "We work closely with developers to ensure pixel-perfect execution. Our team provides detailed specifications and assets. The final product will definitely match the design vision.",
        number: "06",
        img: Implementation,
      },
    ]
  },
  technologies: {
    title: "Tech Stack That We Use",
    techData: {
      tools: [Figma, Sketch, Material, Photoshop, Illustrator, Redmine]
    },
  },
}

export default dataForUiDesign
