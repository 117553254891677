import React from "react";

import './Choose.scss';

const CaseComponent = ({ data }) => {
  const { title, description, number } = data;
  return (
    <div className="dc-ui-case">
      <div className="title-container">
        <div className="number-container">
          <div className="number">{number}</div>
        </div>
        <h3 className="title">{title}</h3>
      </div>
      <div className="description">{description}</div>
    </div>
  );
}

export default function dc({ data }) {
  const { title, description, cases } = data;
  return (
    <div className="dc-ui-wrapper">
      <div className="dc-ui-content">
        <div className="dc-ui-text">
          <h2 className="dc-ui-title">{title}</h2>
          <div className="dc-ui-description">{description}</div>
        </div>
        <div className="dc-ui-cases">
          {cases.map((caseData) => <CaseComponent data={caseData} />)}
        </div>
      </div>
    </div>
  )
}